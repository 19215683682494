<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import PxStarRating from '~/components/px/PxStarRating.vue'
  import { SnpType } from '~/generated/api-clients-generated'
  import { EngineStore } from '~/stores/engine'

  const props = defineProps<{
    quote: QuotedPlanVm
    label: string
    id: string
  }>()

  const { show } = DialogController.use()
  const { maybeDo } = ActionGuard.use()
  const { getId } = Id.use(props.id)
  const { track } = Analytics.use()
  const { route } = AppRoute.use()

  const open = () => {
    show(props.id)
  }

  const { getComponentContent, getTooltipContent } = Cms.use()

  const { content } = await getComponentContent('QuoteCardScoreSidebar')

  const noDocs = computed(() => props.quote.providerCoverage.providers.length === 0)

  const engine = EngineStore.use()

  const noDocsOnAnyPlan = computed(
    () => engine.availableQuotes.filter((x) => x.providerCoverage.inNetworkCount > 0).length === 0
  )

  const dims = computed(() => props.quote.recommendation.score?.retail?.dimensions)

  const { getDisplayedBenefits } = await BenefitDetailsMap.use()

  const { flag } = FeatureFlags.use()

  const configuredCosts = computed(() => flag<string[]>('quote-cost-elements').value)

  const displayedBenefits = computed(() => getDisplayedBenefits(props.quote))

  const onSidebarShown = () => {
    track('PlanFitSidebarShown', {
      planId: props.quote.medicareId,
      openedFrom: route.path
    })
  }

  const showBenefitNeedCoverageLink = computed(
    () => flag<boolean>('benefit-need-coverage-link').value
  )
  const showDoctorNeedCoverageLink = computed(
    () => flag<boolean>('doctor-need-coverage-link').value
  )

  const scoreComponents = computed(() => [
    {
      key: 'overall',
      value: props.quote.score
    },
    {
      key: 'doctors',
      value: noDocs
        ? (props.quote.providerCoverage.inNetworkCount / props.quote.providerCoverage.count) * 100
        : 0,
      features: ['doctorCoverage']
    },
    {
      key: 'cost',
      value: dims.value?.realCost,
      features: ['oopTooltip']
    },
    {
      key: 'risk_protection',
      value: dims.value?.riskProtection,
      features: ['maxOopTooltip']
    },
    {
      key: 'medicare_star_ratings',
      value: ((props.quote.details.starRating || 0) / 5) * 100,
      features: ['starRating']
    }
  ])
</script>

<template>
  <HpAnchor class="my-2 cursor-pointer pb-0 font-semibold leading-none" @click="open">
    {{ label }}
  </HpAnchor>

  <HpSidebar :id="id" :title="content.ext.title" @show="onSidebarShown">
    <aside class="flex flex-col gap-y-4">
      <section class="component-section" v-for="{ key, value, features } in scoreComponents">
        <header :class="['component-section-header', key]">
          <h2 :class="['component-title', key]">
            {{ content.ext[`${key}_title`] }}
          </h2>
          <template v-if="key === 'doctors' && noDocs">
            <HpIcon name="lock" />
          </template>
          <template v-else-if="key === 'doctors' && noDocsOnAnyPlan">
            <span class="font-bold">No score</span>
          </template>
          <template v-else-if="key === 'medicare_star_ratings' && value === 0">
            <span class="font-bold">No score</span>
          </template>
          <template v-else>
            <QuotePlanScoreV2 :score="Math.floor(value)" size="small" />
          </template>
        </header>
        <HpButton
          v-if="key === 'doctors' && noDocs"
          variant="secondary"
          @click="() => maybeDo(() => show('update-doctors-sidebar'))"
          :event-data="() => ({ openedFrom: 'PlanFitSidebar' })"
        >
          <span class="flex items-center">
            <HpIcon name="add" class="mr-1 scale-[85%]" />
            Add your doctors
          </span>
        </HpButton>
        <div v-for="feature in features" :class="['feature', feature]">
          <template
            v-if="
              feature === 'doctorCoverage' && quote.providerCoverage.count > 0 && !noDocsOnAnyPlan
            "
          >
            <HpAccordionItem title="Doctors In-Network" class="coverage-disclosure">
              <template #header-detail>
                {{ quote.providerCoverage.inNetworkCount }} of
                {{ quote.providerCoverage.count }} covered
              </template>
              <ul class="coverage-list" v-for="dr in quote.providerCoverage.providers">
                <li :class="['coverage-item', { covered: !!dr.inNetwork }]">
                  <div>
                    <HpIcon
                      v-if="dr.inNetwork"
                      class="scale-[65%] stroke-success text-success"
                      name="check"
                    />
                    <HpIcon v-else class="text-danger" name="no" />
                    <span>{{ dr.name }}</span>
                  </div>
                  <div class="coverage-item-actions" v-if="showDoctorNeedCoverageLink">
                    <HpAnchor v-if="!dr.inNetwork">I need coverage for this doctor</HpAnchor>
                  </div>
                </li>
              </ul>
            </HpAccordionItem>
          </template>
          <template v-if="feature === 'doctorCoverage' && quote.providerCoverage.providers.length > 0 && noDocsOnAnyPlan">
            <p class="text-sm">
              {{ content.ext.doctors_none_covered_explanation }}
            </p>
          </template>
          <template v-if="feature === 'oopTooltip'">
            <PxTooltip name="TotalEstimatedYearlyCost" text="Total estimated yearly costs" />
            <span>{{ $formatUSD(quote.getYearlyEstCost(configuredCosts)) }}</span>
          </template>
          <template v-if="feature === 'maxOopTooltip'">
            <div class="flex flex-col gap-y-4 w-full">
              <div class="feature maxOopTooltip">
                <PxTooltip name="MaxOutOfPocket" text="Maximum Out-of-Pocket" />
                <span>{{ $formatUSD(quote.details.maxOutOfPocket) }}</span>
              </div>
              <p v-if="quote.snpType === SnpType.DualEligible">
                {{ content.ext['risk_protection_dsnp_explanation'] }}
              </p>
            </div>
          </template>
          <template v-if="feature === 'starRating'">
            <span>Star rating</span>
            <PxStarRating :star-rating="quote.details.starRating!" />
          </template>
          <template v-if="feature === 'benefitsCoverage' && quote.additionalBenefits.count > 0">
            <HpAccordionItem title="Extra Benefits" class="coverage-disclosure">
              <template #header-detail>
                {{ quote.additionalBenefits.coveredCount }} of
                {{ quote.additionalBenefits.count }} covered
              </template>
              <ul class="coverage-list" v-for="ben in displayedBenefits">
                <li :class="['coverage-item', { covered: !!ben.covered }]">
                  <div>
                    <HpIcon
                      v-if="ben.covered"
                      class="scale-[65%] stroke-success text-success"
                      name="check"
                    />
                    <HpIcon v-else class="text-danger" name="no" />
                    <span
                      class="ants"
                      v-if="ben.covered"
                      @click="show(getId(`${ben.name}-benefit-details-sidebar`))"
                      >{{ ben.text }}</span
                    >
                    <span v-else>{{ ben.text }}</span>
                  </div>
                  <div class="coverage-item-actions" v-if="showBenefitNeedCoverageLink">
                    <HpAnchor v-if="!ben.covered">I need coverage for this benefit</HpAnchor>
                  </div>
                </li>
              </ul>
            </HpAccordionItem>
          </template>
        </div>
        <p>
          {{ content.ext[`${key}_description`] }}
        </p>
      </section>
    </aside>
  </HpSidebar>
</template>

<style scoped>
  .component-section {
    @apply flex w-full flex-col gap-y-4;

    .component-section-header {
      @apply flex h-16 w-full items-center justify-between rounded-heading bg-gray-200 px-4;

      &.overall {
        @apply bg-primary-100;
      }

      .component-title {
        @apply text-2xl;

        &.overall {
          @apply text-3xl;
        }
      }
    }

    .feature {
      @apply flex flex-col px-2;

      &.starRating {
        @apply w-full flex-row items-center justify-between text-xl font-semibold;
      }

      &.maxOopTooltip,
      &.oopTooltip {
        @apply w-full flex-row items-center justify-between;

        span {
          @apply text-xl font-semibold;
        }
      }
    }

    p {
      @apply pl-2;
    }
  }
</style>
